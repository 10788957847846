export default {
    lineNumberColor: `#665973`,
    lineNumberBgColor: `#30243d`,
    backgroundColor: `#30243d`,
    textColor: `#e4b781`,
    substringColor: `#bf8ef1`,
    keywordColor: `#df769b`,
    attributeColor: `#e69533`,
    selectorAttributeColor: `#ccbfd9`,
    docTagColor: `#e66533`,
    nameColor: `#ccbfd9`,
    builtInColor: `#e66533`,
    literalColor: `#ccbfd9`,
    bulletColor: `#ccbfd9`,
    codeColor: `#30243d`,
    additionColor: `#a3be8c`,
    regexpColor: `#7f659a`,
    symbolColor: `#ccbfd9`,
    variableColor: `#e4b781`,
    templateVariableColor: `#bf8ef1`,
    linkColor: `#bf8ef1`,
    selectorClassColor: `#d67e5c`,
    typeColor: `#d67e5c`,
    stringColor: `#49e9a6`,
    selectorIdColor: `#d67e5c`,
    quoteColor: `#665973`,
    templateTagColor: `#e66533`,
    deletionColor: `#bf616a`,
    titleColor: `#d67e5c`,
    sectionColor: `#e4b781`,
    commentColor: `#7f659a`,
    metaKeywordColor: `#7f659a`,
    metaColor: `#7f659a`,
    functionColor: `#49ace9`,
    numberColor: `#6258e5`,
  }
  